<template>
  <div>
    <bulkImport />
  </div>
</template>
<script>
import bulkImport from "@/views/boss/transport/driverManage/bulkImport";
export default {
  data() {
    return {

    }
  },
  components: {
    bulkImport,
  },
}
</script>